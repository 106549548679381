import { Injectable, OnDestroy } from '@angular/core';
import { SettingsService } from '@app/common/settings/services/settings.service';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Brand } from '../models/brand.model';

@Injectable({ providedIn: 'root' })
export class BrandService implements OnDestroy {
  private subs = new SubSink();
  private currentBrand$ = new BehaviorSubject<Brand>(null);

  constructor(private settingsService: SettingsService) {
    this.subs.sink = this.getAll()
      .pipe(take(1))
      .subscribe(value => {
        if (value.length) {
          this.set(value[0]);
        }
      });
  }

  getAll() {
    return this.settingsService.getSettings().pipe(map(settings => settings.brands));
  }

  get() {
    return this.currentBrand$.asObservable();
  }

  getSnapshot() {
    return this.currentBrand$.value;
  }

  set(brand: Brand) {
    this.currentBrand$.next(brand);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
