import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/services/auth.service';

@Component({
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    try {
      await this.authService.signOut();
      this.snackBar.open('You\'ve logged out');
      await this.router.navigate(['login']);
    } catch (error) {
      this.snackBar.open('Could not logout');
      console.error(error);
    }
  }
}
