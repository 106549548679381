import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform<T extends Record<string, any>>(
    models: T[],
    groupByProperty: keyof T
  ) {
    const result = new Map<string, T[]>();
    for (const model of models) {
      if (result.get(model[groupByProperty])) {
        result.get(model[groupByProperty]).push(model);
      } else {
        result.set(model[groupByProperty], [model]);
      }
    }

    return Array.from(result.keys()).map(key => ({
      [groupByProperty]: key, groupedItems: result.get(key)
    }));
  }
}
