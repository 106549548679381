import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RoasClassPipe } from './roas-class.pipe';

@NgModule({
  declarations: [RoasClassPipe],
  imports: [CommonModule],
  exports: [RoasClassPipe],
  providers: []
})
export class RoasClassPipeModule {}
