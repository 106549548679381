import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupByPipe } from '@common/pipes/group-pipe.module.ts/group-by.pipe';

@NgModule({
  declarations: [GroupByPipe],
  imports: [CommonModule],
  exports: [GroupByPipe],
  providers: []
})
export class GroupByPipeModule {}
