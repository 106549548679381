import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IncludesPipeModule } from '@common/pipes/includes/includes-pipe.module';
import { NavigationFilterPipe } from '@common/menu/pipes/navigation-filter.pipe';
import { GroupByPipeModule } from '@common/pipes/group-pipe.module.ts/group-by-pipe.module';
import { IncludeFavoritesPipe } from '@common/menu/pipes/include-favorites.pipe';

@NgModule({
  declarations: [
    MenuComponent,
    SidebarMenuComponent,
    NavigationFilterPipe,
    IncludeFavoritesPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltipModule,
    MatListModule,
    MatInputModule,
    IncludesPipeModule,
    GroupByPipeModule
  ],
  exports: [
    MenuComponent,
    SidebarMenuComponent
  ]
})
export class MenuModule { }
