import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationService } from '@common/navigation/navigation.service';
import { FeatureLink } from '@common/navigation/feature-link';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit, OnDestroy {
  featureLinks: FeatureLink[];
  subs = new SubSink();
  favorites$ = this.navigationService.getFavorites();
  filterQuery = '';

  @Output()
  navigated = new EventEmitter<boolean>();

  constructor(private navigationService: NavigationService) { }

  ngOnInit() {
    this.featureLinks = this.navigationService.getFeatureLinks();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
