/**
 * Moves an item one index in an array to another.
 *
 * @param array Array in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which the item should be moved.
 */
export function moveItemInArray<T = any>(array: T[], fromIndex: number, toIndex: number) {
  const from = clamp(fromIndex, array.length - 1);
  const to = clamp(toIndex, array.length - 1);

  if (from === to) {
    return;
  }

  const clone = [...array];
  Array.prototype.splice.call(clone, to, 0, Array.prototype.splice.call(clone, from, 1)[0]);
  return clone;
}

/**
 * Moves an item from one array to another.
 *
 * @param currentArray Array from which to transfer the item.
 * @param targetArray Array into which to put the item.
 * @param currentIndex Index of the item in its current array.
 * @param targetIndex Index at which to insert the item.
 */
export function transferArrayItem<T = any>(currentArray: T[], targetArray: T[],
  currentIndex: number, targetIndex: number): [T[], T[]] {
  const from = clamp(currentIndex, currentArray.length - 1);
  const to = clamp(targetIndex, targetArray.length);

  return [
    currentArray.filter((value, index) => from !== index),
    [...targetArray.slice(0, to), currentArray[from], ...targetArray.slice(to)]
  ];
}

/**
 * Copies an item from one array to another, leaving it in its original position in current array.
 *
 * @param currentArray Array from which to copy the item.
 * @param targetArray Array into which is copy the item.
 * @param currentIndex Index of the item in its current array.
 * @param targetIndex Index at which to insert the item.
 *
 */
export function copyArrayItem<T = any>(currentArray: T[], targetArray: T[], currentIndex: number,
  targetIndex: number) {
  const to = clamp(targetIndex, targetArray.length);

  if (currentArray.length) {
    targetArray.splice(to, 0, currentArray[currentIndex]);
  }
}

/** Clamps a number between zero and a maximum. */
function clamp(value: number, max: number) {
  return Math.max(0, Math.min(max, value));
}
