export const CACHE_MODELS_KEY = 'models';

export const states = [
  { code:'AL', label:'Alabama' },
  { code:'AK', label:'Alaska' },
  { code:'AS', label:'American Samoa' },
  { code:'AZ', label:'Arizona' },
  { code:'AR', label:'Arkansas' },
  { code:'CA', label:'California' },
  { code:'CO', label:'Colorado' },
  { code:'CT', label:'Connecticut' },
  { code:'DE', label:'Delaware' },
  { code:'DC', label:'Washington DC' },
  { code:'FM', label:'Micronesia' },
  { code:'FL', label:'Florida' },
  { code:'GA', label:'Georgia' },
  { code:'GU', label:'Guam' },
  { code:'HI', label:'Hawaii' },
  { code:'ID', label:'Idaho' },
  { code:'IL', label:'Illinois' },
  { code:'IN', label:'Indiana' },
  { code:'IA', label:'Iowa' },
  { code:'KS', label:'Kansas' },
  { code:'KY', label:'Kentucky' },
  { code:'LA', label:'Louisiana' },
  { code:'ME', label:'Maine' },
  { code:'MH', label:'Marshall Islands' },
  { code:'MD', label:'Maryland' },
  { code:'MA', label:'Massachusetts' },
  { code:'MI', label:'Michigan' },
  { code:'MN', label:'Minnesota' },
  { code:'MS', label:'Mississippi' },
  { code:'MO', label:'Missouri' },
  { code:'MT', label:'Montana' },
  { code:'NE', label:'Nebraska' },
  { code:'NV', label:'Nevada' },
  { code:'NH', label:'New Hampshire' },
  { code:'NJ', label:'New Jersey' },
  { code:'NM', label:'New Mexico' },
  { code:'NY', label:'New York' },
  { code:'NC', label:'North Carolina' },
  { code:'ND', label:'North Dakota' },
  { code:'MP', label:'Northern Mariana Islands' },
  { code:'OH', label:'Ohio' },
  { code:'OK', label:'Oklahoma' },
  { code:'OR', label:'Oregon' },
  { code:'PW', label:'Palau' },
  { code:'PA', label:'Pennsylvania' },
  { code:'PR', label:'Puerto Rico' },
  { code:'RI', label:'Rhode Island' },
  { code:'SC', label:'South Carolina' },
  { code:'SD', label:'South Dakota' },
  { code:'TN', label:'Tennessee' },
  { code:'TX', label:'Texas' },
  { code:'UT', label:'Utah' },
  { code:'VT', label:'Vermont' },
  { code:'VI', label:'U.S. Virgin Islands' },
  { code:'VA', label:'Virginia' },
  { code:'WA', label:'Washington' },
  { code:'WV', label:'West Virginia' },
  { code:'WI', label:'Wisconsin' },
  { code:'WY', label:'Wyoming' },
  { code:'AA', label:'Armed Forces Americas' },
  { code:'AE', label:'Armed Forces Europe' },
  { code:'AP', label:'Armed Forces Pacific' }
];

export const currencies = [
  { value: 'AFN', label: 'Afghan Afghani (AFN ؋)' },
  { value: 'ALL', label: 'Albanian Lek (ALL)' },
  { value: 'DZD', label: 'Algerian Dinar (DZD)' },
  { value: 'AOA', label: 'Angolan Kwanza (AOA Kz)' },
  { value: 'ARS', label: 'Argentine Peso (ARS $)' },
  { value: 'AMD', label: 'Armenian Dram (AMD ֏)' },
  { value: 'AWG', label: 'Aruban Florin (AWG)' },
  { value: 'AUD', label: 'Australian Dollar (AUD $)' },
  { value: 'AZN', label: 'Azerbaijani Manat (AZN ₼)' },
  { value: 'BSD', label: 'Bahamian Dollar (BSD $)' },
  { value: 'BDT', label: 'Bangladeshi Taka (BDT ৳)' },
  { value: 'BBD', label: 'Barbadian Dollar (BBD $)' },
  { value: 'BZD', label: 'Belize Dollar (BZD $)' },
  { value: 'BMD', label: 'Bermudan Dollar (BMD $)' },
  { value: 'BOB', label: 'Bolivian Boliviano (BOB Bs)' },
  { value: 'BAM', label: 'Bosnia-Herzegovina Convertible Mark (BAM KM)' },
  { value: 'BWP', label: 'Botswanan Pula (BWP P)' },
  { value: 'BRL', label: 'Brazilian Real (BRL R$)' },
  { value: 'GBP', label: 'British Pound (GBP £)' },
  { value: 'BND', label: 'Brunei Dollar (BND $)' },
  { value: 'BGN', label: 'Bulgarian Lev (BGN)' },
  { value: 'BIF', label: 'Burundian Franc (BIF)' },
  { value: 'KHR', label: 'Cambodian Riel (KHR ៛)' },
  { value: 'CAD', label: 'Canadian Dollar (CAD $)' },
  { value: 'CVE', label: 'Cape Verdean Escudo (CVE)' },
  { value: 'KYD', label: 'Cayman Islands Dollar (KYD $)' },
  { value: 'XAF', label: 'Central African CFA Franc (XAF FCFA)' },
  { value: 'XPF', label: 'CFP Franc (XPF CFPF)' },
  { value: 'CLP', label: 'Chilean Peso (CLP $)' },
  { value: 'CNY', label: 'Chinese Yuan (CNY ¥)' },
  { value: 'COP', label: 'Colombian Peso (COP $)' },
  { value: 'KMF', label: 'Comorian Franc (KMF CF)' },
  { value: 'CDF', label: 'Congolese Franc (CDF)' },
  { value: 'CRC', label: 'Costa Rican Colón (CRC ₡)' },
  { value: 'CZK', label: 'Czech Koruna (CZK Kč)' },
  { value: 'DKK', label: 'Danish Krone (DKK kr)' },
  { value: 'DJF', label: 'Djiboutian Franc (DJF)' },
  { value: 'DOP', label: 'Dominican Peso (DOP $)' },
  { value: 'XCD', label: 'East Caribbean Dollar (XCD $)' },
  { value: 'EGP', label: 'Egyptian Pound (EGP E£)' },
  { value: 'ETB', label: 'Ethiopian Birr (ETB)' },
  { value: 'EUR', label: 'Euro (EUR €)' },
  { value: 'FKP', label: 'Falkland Islands Pound (FKP £)' },
  { value: 'FJD', label: 'Fijian Dollar (FJD $)' },
  { value: 'GMD', label: 'Gambian Dalasi (GMD)' },
  { value: 'GEL', label: 'Georgian Lari (GEL ₾)' },
  { value: 'GIP', label: 'Gibraltar Pound (GIP £)' },
  { value: 'GTQ', label: 'Guatemalan Quetzal (GTQ Q)' },
  { value: 'GNF', label: 'Guinean Franc (GNF FG)' },
  { value: 'GYD', label: 'Guyanaese Dollar (GYD $)' },
  { value: 'HTG', label: 'Haitian Gourde (HTG)' },
  { value: 'HNL', label: 'Honduran Lempira (HNL L)' },
  { value: 'HKD', label: 'Hong Kong Dollar (HKD HK$)' },
  { value: 'HUF', label: 'Hungarian Forint (HUF Ft)' },
  { value: 'ISK', label: 'Icelandic Króna (ISK kr)' },
  { value: 'INR', label: 'Indian Rupee (INR ₹)' },
  { value: 'IDR', label: 'Indonesian Rupiah (IDR Rp)' },
  { value: 'ILS', label: 'Israeli New Shekel (ILS ₪)' },
  { value: 'JMD', label: 'Jamaican Dollar (JMD $)' },
  { value: 'JPY', label: 'Japanese Yen (JPY ¥)' },
  { value: 'KZT', label: 'Kazakhstani Tenge (KZT ₸)' },
  { value: 'KES', label: 'Kenyan Shilling (KES)' },
  { value: 'KGS', label: 'Kyrgystani Som (KGS)' },
  { value: 'LAK', label: 'Laotian Kip (LAK ₭)' },
  { value: 'LBP', label: 'Lebanese Pound (LBP L£)' },
  { value: 'LSL', label: 'Lesotho Loti (LSL)' },
  { value: 'LRD', label: 'Liberian Dollar (LRD $)' },
  { value: 'MOP', label: 'Macanese Pataca (MOP)' },
  { value: 'MKD', label: 'Macedonian Denar (MKD)' },
  { value: 'MWK', label: 'Malawian Kwacha (MWK)' },
  { value: 'MYR', label: 'Malaysian Ringgit (MYR RM)' },
  { value: 'MVR', label: 'Maldivian Rufiyaa (MVR)' },
  { value: 'MUR', label: 'Mauritian Rupee (MUR Rs)' },
  { value: 'MXN', label: 'Mexican Peso (MXN $)' },
  { value: 'MDL', label: 'Moldovan Leu (MDL)' },
  { value: 'MNT', label: 'Mongolian Tugrik (MNT ₮)' },
  { value: 'MAD', label: 'Moroccan Dirham (MAD)' },
  { value: 'MZN', label: 'Mozambican Metical (MZN)' },
  { value: 'MMK', label: 'Myanmar Kyat (MMK K)' },
  { value: 'NAD', label: 'Namibian Dollar (NAD $)' },
  { value: 'NPR', label: 'Nepalese Rupee (NPR Rs)' },
  { value: 'ANG', label: 'Netherlands Antillean Guilder (ANG)' },
  { value: 'TWD', label: 'New Taiwan Dollar (TWD $)' },
  { value: 'NZD', label: 'New Zealand Dollar (NZD $)' },
  { value: 'NIO', label: 'Nicaraguan Córdoba (NIO C$)' },
  { value: 'NGN', label: 'Nigerian Naira (NGN ₦)' },
  { value: 'NOK', label: 'Norwegian Krone (NOK kr)' },
  { value: 'PKR', label: 'Pakistani Rupee (PKR Rs)' },
  { value: 'PAB', label: 'Panamanian Balboa (PAB)' },
  { value: 'PGK', label: 'Papua New Guinean Kina (PGK)' },
  { value: 'PYG', label: 'Paraguayan Guarani (PYG ₲)' },
  { value: 'PEN', label: 'Peruvian Sol (PEN)' },
  { value: 'PHP', label: 'Philippine Piso (PHP ₱)' },
  { value: 'PLN', label: 'Polish Zloty (PLN zł)' },
  { value: 'QAR', label: 'Qatari Rial (QAR)' },
  { value: 'RON', label: 'Romanian Leu (RON lei)' },
  { value: 'RUB', label: 'Russian Ruble (RUB ₽)' },
  { value: 'RWF', label: 'Rwandan Franc (RWF RF)' },
  { value: 'WST', label: 'Samoan Tala (WST)' },
  { value: 'STD', label: 'São Tomé & Príncipe Dobra (1977–2017) (STD)' },
  { value: 'SAR', label: 'Saudi Riyal (SAR)' },
  { value: 'RSD', label: 'Serbian Dinar (RSD)' },
  { value: 'SCR', label: 'Seychellois Rupee (SCR)' },
  { value: 'SLL', label: 'Sierra Leonean Leone (SLL)' },
  { value: 'SGD', label: 'Singapore Dollar (SGD $)' },
  { value: 'SBD', label: 'Solomon Islands Dollar (SBD $)' },
  { value: 'ZAR', label: 'South African Rand (ZAR R)' },
  { value: 'KRW', label: 'South Korean Won (KRW ₩)' },
  { value: 'LKR', label: 'Sri Lankan Rupee (LKR Rs)' },
  { value: 'SHP', label: 'St. Helena Pound (SHP £)' },
  { value: 'SRD', label: 'Surinamese Dollar (SRD $)' },
  { value: 'SZL', label: 'Swazi Lilangeni (SZL)' },
  { value: 'SEK', label: 'Swedish Krona (SEK kr)' },
  { value: 'CHF', label: 'Swiss Franc (CHF)' },
  { value: 'TJS', label: 'Tajikistani Somoni (TJS)' },
  { value: 'TZS', label: 'Tanzanian Shilling (TZS)' },
  { value: 'THB', label: 'Thai Baht (THB ฿)' },
  { value: 'TOP', label: 'Tongan Paʻanga (TOP T$)' },
  { value: 'TTD', label: 'Trinidad & Tobago Dollar (TTD $)' },
  { value: 'TRY', label: 'Turkish Lira (TRY ₺)' },
  { value: 'UGX', label: 'Ugandan Shilling (UGX)' },
  { value: 'UAH', label: 'Ukrainian Hryvnia (UAH ₴)' },
  { value: 'AED', label: 'United Arab Emirates Dirham (AED)' },
  { value: 'UYU', label: 'Uruguayan Peso (UYU $)' },
  { value: 'USD', label: 'US Dollar (USD $)' },
  { value: 'UZS', label: 'Uzbekistani Som (UZS)' },
  { value: 'VUV', label: 'Vanuatu Vatu (VUV)' },
  { value: 'VND', label: 'Vietnamese Dong (VND ₫)' },
  { value: 'XOF', label: 'West African CFA Franc (XOF F CFA)' },
  { value: 'YER', label: 'Yemeni Rial (YER)' },
  { value: 'ZMW', label: 'Zambian Kwacha (ZMW ZK)' }
];

export const readableHours = [
  { value: 0, label: '12:00 AM' },
  { value: 1, label: '1:00 AM' },
  { value: 2, label: '2:00 AM' },
  { value: 3, label: '3:00 AM' },
  { value: 4, label: '4:00 AM' },
  { value: 5, label: '5:00 AM' },
  { value: 6, label: '6:00 AM' },
  { value: 7, label: '7:00 AM' },
  { value: 8, label: '8:00 AM' },
  { value: 9, label: '9:00 AM' },
  { value: 10, label: '10:00 AM' },
  { value: 11, label: '11:00 AM' },
  { value: 12, label: '12:00 PM' },
  { value: 13, label: '1:00 PM' },
  { value: 14, label: '2:00 PM' },
  { value: 15, label: '3:00 PM' },
  { value: 16, label: '4:00 PM' },
  { value: 17, label: '5:00 PM' },
  { value: 18, label: '6:00 PM' },
  { value: 19, label: '7:00 PM' },
  { value: 20, label: '8:00 PM' },
  { value: 21, label: '9:00 PM' },
  { value: 22, label: '10:00 PM' },
  { value: 23, label: '11:00 PM' }
];
