import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { DurationLike } from 'luxon';
import { forkJoin, from, merge } from 'rxjs';
import {
  bufferTime,
  concatMap,
  filter,
  map,
  mapTo,
  mergeMap,
  takeWhile
} from 'rxjs/operators';
import {
  CacheDriver,
  CacheMatchOptions,
  CacheSetOptions,
  DEFAULT_CACHE_SET_OPTIONS
} from '../cache-driver';

export interface CacheDriverDefinition {
  driver: CacheDriver;
  name: string;
}

export const CACHE_DRIVERS = new InjectionToken<CacheDriverDefinition[]>('CACHE_DRIVERS');

@Injectable()
export class CacheService implements OnDestroy, CacheDriver {
  constructor(
    @Optional()
    @Inject(CACHE_DRIVERS)
    private driverDefinitions: CacheDriverDefinition[] = []
  ) {}

  /**
   * @todo rename to findDriverByName or findDriver
   */
  driver(name: string) {
    return this.driverDefinitions.find(driver => driver.name === name)?.driver;
  }

  ngOnDestroy() {
    this.destroy();
  }

  get<T>(key: string, youngerThan?: DurationLike) {
    const drivers = this.driverDefinitions.map(definition => definition.driver);
    let index = -1;

    return from(drivers.map((driver) => driver.get<T>(key, youngerThan))).pipe(
      concatMap((observable) => {
        ++index;
        return observable;
      }),
      takeWhile((value) => typeof value === 'undefined', true),
      filter((value) => typeof value !== 'undefined' || index === drivers.length - 1)
    );
  }

  set<T>(key: string, value: T, options: CacheSetOptions = {}) {
    const mergedOptions = { ...DEFAULT_CACHE_SET_OPTIONS, ...options };
    const drivers = this.getDrivers();
    const sources = drivers.map(driver => driver.set<T>(key, value, mergedOptions));
    return forkJoin(sources).pipe(mapTo(true));
  }

  forget(match: CacheMatchOptions) {
    const drivers = this.getDrivers();
    const sources = drivers.map(driver => driver.forget(match));
    return forkJoin(sources).pipe(mapTo(true));
  }

  flush() {
    const drivers = this.driverDefinitions.map(definition => definition.driver);
    const sources = drivers.map((driver) => driver.flush());
    return forkJoin(sources).pipe(mapTo(true));
  }

  observe<T>(key: string) {
    return merge(
      this.driverDefinitions.map(definition => ({
        observable: definition.driver.observe<T>(key),
        driver: definition.name
      }))
    ).pipe(
      mergeMap((observableWithDriver) => observableWithDriver.observable.pipe(
        map((value) => ({ value, driver: observableWithDriver.driver }))
      )),
      bufferTime(100),
      concatMap((buffer) => {
        return from(
          buffer
            .reduce(
              (
                filteredBuffer,
                valueWithDriver
              ) => {
                if (
                  filteredBuffer.find(
                    (filteredBufferItem) =>
                      filteredBufferItem.driver !== valueWithDriver.driver && isEqual(
                        filteredBufferItem.value,
                        valueWithDriver.value
                      )
                  )
                ) {
                  return filteredBuffer;
                }

                return [...filteredBuffer, valueWithDriver];
              },
              <{ driver: string; value: T; }[]>[]
            )
            .map((valueWithDriver) => valueWithDriver.value)
        );
      })
    );
  }

  destroy() {
    for (const driverDefinition of this.driverDefinitions) {
      driverDefinition.driver.destroy();
    }
  }

  private getDrivers() {
    return this.driverDefinitions.map(definition => definition.driver);
  }
}
