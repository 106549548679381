import { Injectable } from '@angular/core';
import { AuthService } from '@common/auth/services/auth.service';
import { FeatureLink } from '@common/navigation/feature-link';
import { featureLinks } from '@common/navigation/feature-links';
import { UserService } from '@features/user/services/user.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const FAVORITE_CATEGORY_NAME = 'favorite';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {}

  getFeatureLinks() {
    const userPermissions = this.authService.getUserSnapshot()?.allowedFns || [];
    return featureLinks.filter((featureLink) =>
      featureLink.permissionsToDisplay.length === 0 ||
      featureLink.permissionsToDisplay.some((permission) => userPermissions.includes(permission)
      )
    );
  }

  getCategories() {
    const userFeatureLinks = this.getFeatureLinks();
    return [
      ...new Set(userFeatureLinks.map(featureLink => featureLink.category))
    ].filter(featureLink => !!featureLink);
  }

  addFavorite(favorite: string) {
    return this.updateFavorites([
      ...new Set([...this.authService.getUserSnapshot().favorites, favorite])
    ]);
  }

  removeFavorite(favorite: string) {
    return this.updateFavorites([
      ...new Set([
        ...this.authService
          .getUserSnapshot()
          .favorites.filter(userFavorite => userFavorite !== favorite)
      ])
    ]);
  }

  updateFavorites(favorites: string[]) {
    const user = this.authService.getUserSnapshot();
    const oldFavorites = [...user.favorites];
    this.authService.saveUser({
      ...user,
      favorites
    });
    return this.userService.updateUserMenus({
      favorites
    }).pipe(catchError(error=>{
      this.authService.saveUser({
        ...user,
        favorites: oldFavorites
      });
      return throwError(error);
    }));
  }

  findLinksByIds(favorites: string[]) {
    const links: FeatureLink[] = [];
    const featureLinks = this.getFeatureLinks();

    for (const favorite of favorites) {
      const foundLink = featureLinks.find(featureLink => featureLink.path === favorite);
      if (foundLink) {
        links.push(foundLink);
      }
    }

    return links;
  }

  getFavoriteFeatureLinks() {
    return this.authService.getUser().pipe(map((user) =>
      this.findLinksByIds(user?.favorites || [])));
  }

  getFavorites() {
    return this.authService.getUser().pipe(map(user=>user?.favorites || []));
  }

  getFavoritesSnapshot() {
    return this.authService.getUserSnapshot()?.favorites || [];
  }

  filterLinks(phrase: string, featureLinks: FeatureLink[]) {
    if(!phrase?.trim()) {
      return [...featureLinks];
    }

    return featureLinks.filter(featureLink =>
      featureLink.tags?.some(tag => tag.toLowerCase().includes(phrase.toLowerCase())) ||
    featureLink.label.toLowerCase().includes(phrase.toLowerCase()));
  }
}
