import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { KeysToCamelCase, KeysToSnakeCase } from '../common.types';

/**
 * Transform object (model) keys from snake_case to camelCase - deep/recursive
 *
 * camelcaseKeys in not properly typed so as unknown hack is used
 *
 * @deprecated
 */
export function toCamelcase<T = any>(model: T) {
  return <KeysToCamelCase<T>><unknown>camelcaseKeys(model, { deep: true });
}

/**
 * Transform object (model) keys from camelCase to snake_case - deep/recursive
 *
 * snakecaseKeys in not properly typed so as unknown hack is used
 *
 * @deprecated
 * @todo i do not think the return type is needed here? unclear
 */
export function toSnakecase<T = any>(model: T): KeysToSnakeCase<T> {
  return snakecaseKeys(model, { deep: true });
}
