import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  refreshCallback: ()=> void;
  loading$ = new BehaviorSubject(new Set<any>());

  constructor(private readonly snackBar: MatSnackBar) {}

  refresh() {
    if (this.refreshCallback) {
      this.refreshCallback();
    } else {
      this.snackBar.open('There is nothing to refresh');
    }
  }

  isLoading(key?: any) {
    return this.loading$.pipe(
      map((value) => {
        if (!key) {
          return value.size > 0;
        }

        return value.has(key);
      })
    );
  }

  addLoading(key: any) {
    setTimeout(()=>{
      const value = new Set(Array.from(this.loading$.value));
      value.add(key);
      this.loading$.next(value);
    });
  }

  removeLoading(key: any) {
    setTimeout(()=>{
      const value = new Set(Array.from(this.loading$.value));
      value.delete(key);
      this.loading$.next(value);
    });
  }

  clearRefresh() {
    this.refreshCallback = null;
  }

  setRefresh(callback: ()=> any) {
    this.refreshCallback = callback;
  }

  hasRefreshCallback() {
    return this.refreshCallback !== null;
  }
}
