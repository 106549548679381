import { DateTime, DurationLike } from 'luxon';
import { asyncScheduler, fromEvent, Observable, of } from 'rxjs';
import { filter, map, observeOn, publishReplay, refCount } from 'rxjs/operators';
import { compareAsc } from '../utils/date.utils';
import {
  CacheDriver,
  CacheMatchOptions,
  CacheSetOptions,
  DEFAULT_CACHE_SET_OPTIONS,
  WrappedValue
} from './cache-driver';

interface SerializedValue<T> {
  createdAt: string;
  expiresAt: string | null;
  tags: string[]|null;
  value: T;
}

export class WebStorageDriver implements CacheDriver {
  storage: Storage;
  observe$: Observable<any>;

  constructor(private window: Window, storageType: 'local' | 'session' = 'local',
    private prefix: string = 'cache:') {
    this.storage = storageType === 'session' ?
      this.window.sessionStorage :
      this.window.localStorage;

    if (this.window) {
      this.observe$ = fromEvent(this.window, 'storage').pipe(
        publishReplay(1),
        refCount()
      );
    }

    this.flush(true);
  }

  get<T>(key: string, youngerThan?: DurationLike) {
    const now = new Date();
    const wrappedValue: WrappedValue<T> = this.deserialize(this.storage.getItem(this.prefix + key));
    if (!wrappedValue) {
      return of<undefined>(undefined).pipe(observeOn(asyncScheduler));
    }

    if (!this.isYoungEnough(wrappedValue, youngerThan, now)) {
      return of<undefined>(undefined).pipe(observeOn(asyncScheduler));
    }

    return of(this.expireIfNeeded<T>(key, wrappedValue, now)).pipe(observeOn(asyncScheduler));
  }

  private isYoungEnough<T>(wrappedValue: WrappedValue<T>, youngerThan?: DurationLike,
    now?: Date) {
    if (!youngerThan) {
      return true;
    }

    const nowDateTime = now ? DateTime.fromJSDate(now) : DateTime.now();
    const checkDate = nowDateTime.minus(youngerThan).toJSDate();
    const compareResult = compareAsc(wrappedValue.createdAt, checkDate);
    if (compareResult === -1) {
      return false;
    }

    return true;
  }

  private expireIfNeeded<T>(key: string, wrappedValue: WrappedValue<T>, now?: Date) {
    const compareResult = compareAsc(wrappedValue.expiresAt, now ? now : new Date());
    if (compareResult < 1) {
      this.storage.removeItem(this.prefix + key);
    }

    if (compareResult === -1) {
      return undefined;
    }

    return wrappedValue.value;
  }

  set<T>(key: string, value: T, options: CacheSetOptions = {}) {
    let result = true;
    const mergedOptions = { ...DEFAULT_CACHE_SET_OPTIONS, ...options };
    const nowDateTime = DateTime.now();
    const wrappedValue: WrappedValue<T> = {
      value,
      tags: Array.isArray(mergedOptions.tags) ? mergedOptions.tags : null,
      createdAt: nowDateTime.toJSDate(),
      expiresAt: mergedOptions?.duration ?
        nowDateTime.plus(mergedOptions.duration).toJSDate() :
        null
    };
    try {
      this.storage.setItem(this.prefix + key, this.serialize(wrappedValue));
    } catch (error) {
      console.error('Could not set storage item');
      console.error(error);
      result = false;
    }

    return of(result).pipe(observeOn(asyncScheduler));
  }

  observe<T>(key: string) {
    return this.observe$.pipe(
      filter(event => event.key === this.prefix + key),
      map(event => {
        const wrappedValue = this.deserialize<T>(event.newValue);
        return wrappedValue?.value;
      })
    );
  }

  flush(expiredOnly = false) {
    if (!expiredOnly && !this.prefix) {
      this.storage.clear();
    }

    const now = new Date();
    for (const cachedKey of Object.keys(this.storage)) {
      if (this.prefix && !cachedKey.startsWith(this.prefix)) {
        continue;
      }

      if (expiredOnly) {
        const key = this.stripPrefix(cachedKey);
        const cachedValue = this.storage.getItem(cachedKey);
        const wrappedValue: WrappedValue<unknown> = this.deserialize(cachedValue);
        this.expireIfNeeded(key, wrappedValue, now);
      } else {
        this.storage.removeItem(cachedKey);
      }
    }

    return of(true).pipe(observeOn(asyncScheduler));
  }

  private findItemKeys(match: CacheMatchOptions): string[] {
    if (match.key) {
      return [match.key];
    } else if(match.tags) {
      const matchedKeys: string[] = [];
      for (const cachedKey of Object.keys(this.storage)) {
        if (this.prefix && !cachedKey.startsWith(this.prefix)) {
          continue;
        }

        const deserialized = this.deserialize(this.storage.getItem(cachedKey));
        if (deserialized.tags && match.tags.every(tag=> deserialized.tags.includes(tag))) {
          matchedKeys.push(this.stripPrefix(cachedKey));
        }
      }

      return matchedKeys;
    }
  }

  forget(match: CacheMatchOptions) {
    const foundItemKeys = this.findItemKeys(match);
    if (foundItemKeys.length === 0) {
      return of(false).pipe(observeOn(asyncScheduler));
    }

    const result = true;
    for (const key of foundItemKeys) {
      this.storage.removeItem(this.prefix + key);
    }

    return of(result).pipe(observeOn(asyncScheduler));
  }

  private stripPrefix(cachedKey: string) {
    if (!this.prefix) {
      return cachedKey;
    }

    return cachedKey.replace(this.prefix, '');
  }

  private deserialize<T>(value?: string) {
    if (!value) {
      return;
    }

    const parsed: SerializedValue<T> = JSON.parse(value);
    return <WrappedValue<T>>{
      ...parsed,
      createdAt: DateTime.fromISO(parsed.createdAt).toJSDate(),
      expiresAt: parsed.expiresAt ? DateTime.fromISO(parsed.expiresAt).toJSDate() : null
    };
  }

  private serialize(value: any) {
    return JSON.stringify(value);
  }

  destroy() {}
}
