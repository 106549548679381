import { DateTime } from 'luxon';

export function stripQuery(urlWithQuery: string) {
  type StrippedQuery = {
    queryParams: string;
    url: string;
  };

  if (typeof urlWithQuery !== 'string' || urlWithQuery.length === 0) {
    return <StrippedQuery>{ url: urlWithQuery, queryParams: '' };
  }

  const split = urlWithQuery.split('?');
  if (split.length === 1) {
    return <StrippedQuery>{ url: split[0], queryParams: '' };
  }

  return <StrippedQuery>{ url: split[0], queryParams: split[1] };
}

/** Override given keys from string to Date */
export type StringToDate<T, K extends keyof T> = {
  [Key in keyof T]: Key extends K ? Date : T[Key];
};

// export type MapDates<T, K extends keyof T> = Omit<T, K> & Record<K, Date>;
/**
 * Parse ISO date strings to Js Date
 */
export function parseDates<T extends Record<string, any>, K extends keyof T>(data: T, keys: K[]) {
  const newData: StringToDate<T, K> = { ...data };
  if (keys.length === 0) {
    return newData;
  }

  for (const key of keys) {
    if (typeof data[key] === 'string' && data[key] !== '') {
      const date = DateTime.fromISO(data[key]);
      // <any> is a dirty workaround - type is defined properly above, not sure why it's
      // necessary
      newData[key] = date.isValid ? <any>date.toJSDate() : undefined;
    }
  }

  return newData;
}
