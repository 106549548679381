<div class="container">
  <div class="search-wrapper">
    <mat-form-field>
      <input [(ngModel)]="filterQuery" placeholder="Search..." matInput />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <ng-container *ngIf="favorites$ | async as favorites">
    <ng-container
      *ngFor="
        let featureLinksByCategory of featureLinks
          | includeFavorites
          | async
          | navigationFilter: filterQuery
          | groupBy: 'category'
      "
    >
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="category-title">{{ featureLinksByCategory.category }}</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="module-grid r :w :c-6 :c-4:xs :c-4:sm :c-4:md :c-3:md+">
          <a
            class="module-item"
            *ngFor="let featureLink of featureLinksByCategory.groupedItems"
            [routerLink]="'/' + featureLink.path"
          >
            <mat-card matRipple>
              <div>
                <div class="module-icon">
                  <mat-icon>{{ featureLink.icon }}</mat-icon>
                </div>
                <div class="module-label">{{ featureLink.label }}</div>
              </div>
              <button
                (click)="resolveFavorite(featureLink, $event)"
                class="favorite-icon-button"
                mat-icon-button
                [matTooltip]="
                  (favorites | includes: featureLink.path)
                    ? 'Delete from favorites'
                    : 'Add to favorites'
                "
              >
                <mat-icon
                  [color]="
                    (favorites | includes: featureLink.path) ? 'primary' : null
                  "
                  >star</mat-icon
                >
              </button>
            </mat-card>
          </a>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
</div>
