import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from '@app/common/cache/services/cache.service';
import { AppWindow, WindowService } from '@app/window.service';
import {
  BroadcastChannelService,
  LOGIN_MESSAGE,
  LOGOUT_MESSAGE
} from '@common/services/broadcast-channel.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  filter,
  finalize,
  map,
  pairwise,
  startWith,
  switchMap,
  take
} from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ReLoginDialogComponent } from '../dialogs/re-login/re-login-dialog.component';
import { AuthUser } from '../models/auth-user.model';

const LOCAL_STORAGE_USER_KEY = 'user';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private user$ = new BehaviorSubject<AuthUser>(null);
  private auth2: gapi.auth2.GoogleAuth = null;
  private subs = new SubSink();
  public window: AppWindow;
  gapiLoaded$ = new BehaviorSubject<boolean>(false);
  reLoginRef: MatDialogRef<ReLoginDialogComponent, boolean> = null;

  constructor(
    private http: HttpClient,
    private windowService: WindowService,
    private dialog: MatDialog,
    private cacheService: CacheService,
    private broadcastChannelService: BroadcastChannelService
  ) {
    this.window = this.windowService.getNativeWindow();

    if (this.window.gapi) {
      this.gapiLoaded$.next(true);
    } else {
      this.window.onGapiLoad = () => {
        this.gapiLoaded$.next(true);
      };
    }

    this.subs.sink = this.broadcastChannelService.message$.subscribe(
      (message) => {
        if (message === LOGIN_MESSAGE) {
          this.initUserFromStorage().catch(()=>{
            console.warn('[AuthService] Could not initiate from storage (on broadcast message)');
          });
        }
      }
    );
  }

  googleInit() {
    return this.gapiLoaded$.pipe(
      filter(isLoaded => isLoaded),
      take(1),
      switchMap(() => {
        return new Observable<gapi.auth2.CurrentUser>(observer => {
          gapi.load('auth2', () => {
            this.auth2 = gapi.auth2.init({
              client_id: environment.googleOAuthClientId,
              cookie_policy: 'single_host_origin',
              scope: 'profile email'
            });

            this.auth2.then(
              (auth) => {
                if (this.auth2.isSignedIn) {
                  observer.next(auth.currentUser);
                  observer.complete();
                } else {
                  observer.next(null);
                  observer.complete();
                }
              },
              (error) => {
                observer.error(error);
              }
            );
          });
        });
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  isUserLoggedIn() {
    return this.user$.value !== null;
  }

  getUser() {
    return this.user$.asObservable();
  }

  patchUser(data: Partial<AuthUser>) {
    this.saveUser({ ...this.user$.value, ...data });
  }

  onSignIn() {
    return this.user$.pipe(
      startWith(null),
      pairwise(),
      filter(pair => pair[0] === null && pair[1] !== null),
      map(pair => pair[1])
    );
  }

  onSignOut() {
    return this.user$.pipe(
      startWith(null),
      pairwise(),
      filter(pair => pair[0] !== null && pair[1] === null),
      map(pair => pair[0])
    );
  }

  getUserSnapshot() {
    return this.user$.value;
  }

  hasAccess(functionNames: string[] | string, any = false) {
    if (!this.isUserLoggedIn()) {
      return false;
    }

    if (!Array.isArray(functionNames) && typeof functionNames === 'string') {
      functionNames = [functionNames];
    }

    return any ?
      functionNames.some(name => this.getUserSnapshot().allowedFns.includes(name)) :
      functionNames.every(name => this.getUserSnapshot().allowedFns.includes(name));
  }

  getToken() {
    if (!this.isUserLoggedIn()) {
      return;
    }

    return this.getUserSnapshot().token;
  }

  saveUser(user: AuthUser) {
    this.cacheService.set(LOCAL_STORAGE_USER_KEY, user, {
      /**
       * User should be cached for 24 hours - 1 minute. We use idToken
       * for internal user authentication. We do not make any requests to Google API.
       * Token itself has 1 hour expiration time, but we do not validate it
       * on backend during processing requests. And it has 24 hours TTL in
       * tokens DynamoDB table.
       */
      duration: { minutes: 60 * 24 - 1 }
    });
    this.user$.next(user);
  }

  async initUserFromStorage() {
    const user = await this.cacheService.get<AuthUser>(LOCAL_STORAGE_USER_KEY).toPromise();
    if (user) {
      this.user$.next(user);
    }

    return !!user;
  }

  async googleSignIn() {
    if (this.auth2 === null) {
      console.debug('Google API not initialized');
      try {
        await this.googleInit().toPromise();
      } catch (error) {
        console.error('Could not initialize Google API', error);
        throw error;
      }
    }

    const googleUser: gapi.auth2.GoogleUser = await this.auth2.signIn({
      prompt: 'select_account'
    });
    const token = googleUser.getAuthResponse().id_token;
    return this.signIn(token);
  }

  async signIn(token: string) {
    const result = await this.http
      .get<AuthUser>(`${environment.iamService}login`, {
        params:{ token }
      })
      .toPromise();
    if (!result.name) {
      return false;
    }

    // DO NOT REMOVE. USEFUL FOR TESTING
    // result.allowedFns = result.allowedFns.filter((allowedFn) => {
    //   return ![
    //   ].includes(allowedFn);
    // });

    this.saveUser({ token, ...result });
    this.broadcastChannelService.postMessage(LOGIN_MESSAGE);
  }

  async signOut() {
    if (this.auth2?.isSignedIn) {
      await this.auth2.signOut();
    }

    this.saveUser(null);
    this.broadcastChannelService.postMessage(LOGOUT_MESSAGE);
  }

  showReloginDialog() {
    if (this.reLoginRef) {
      return throwError('already_opened');
    }

    this.reLoginRef = this.dialog.open(ReLoginDialogComponent, {
      width: '400px',
      disableClose: true,
      panelClass: 'labeled-dialog'
    });

    return this.reLoginRef.afterClosed().pipe(finalize(() => this.reLoginRef = null));
  }
}
