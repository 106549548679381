import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/services/auth.service';
import { Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { UnauthorizedException } from '../exceptions/unauthorized.exception';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  isDialogOpen = false;
  refresh$: Subject<void>;

  constructor(private injector: Injector, private authService: AuthService,
    private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const response = next.handle(this.decorateWithTokenHeader(request));

    return response.pipe(catchError(error => {
      const mappedError = this.mapErrorResponse(error);
      if (mappedError instanceof UnauthorizedException) {
        if (this.isDialogOpen) {
          return this.refresh$.pipe(
            switchMap(() => next.handle(this.decorateWithTokenHeader(request)))
          );
        }

        this.isDialogOpen = true;
        this.refresh$ = new Subject();
        return this.authService.showReloginDialog().pipe(
          tap((result) => {
            if (result === false) {
              this.isDialogOpen = false;
              this.refresh$.complete();
              throw new Error('logout');
            }
          }),
          filter(result => result === true),
          finalize(() => {
            this.isDialogOpen = false;
            this.refresh$.next();
            this.refresh$.complete();
          }),
          switchMap(() => next.handle(this.decorateWithTokenHeader(request)))
        );
      }

      return throwError(mappedError);
    }));
  }

  decorateWithTokenHeader(request: HttpRequest<any>) {
    const token = this.authService.getToken();
    if (!token) {
      return request;
    }

    return request.clone({ headers: request.headers.set('token', token) });
  }

  mapErrorResponse(response: HttpErrorResponse) {
    const isCORSIssue = response.status === 0 && response.statusText === 'Unknown Error';
    const isAuthorizerIssue = response.status === 500 &&
     response.headers.get('x-amzn-errortype') === 'AuthorizerConfigurationException';
    if (isCORSIssue || isAuthorizerIssue || response.status === 401) {
      return new UnauthorizedException();
    }

    return response;
  }
}
