import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { CacheSetOptions } from '../cache-driver';
import { CACHE_CONTEXT } from '../cache.token';
import { CacheService } from '../services/cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cacheService: CacheService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const cache = request.context.get(CACHE_CONTEXT);
    let key: string | null = null;
    if (cache.get) {
      key = this.generateKey(request);
      return this.cacheService
        .get<HttpResponse<unknown> & { headers: Record<string, string>; }>(key,
          cache.youngerThan ? cache.youngerThan : null)
        .pipe(
          concatMap(cacheResponse => {
            if (!cacheResponse) {
              return this.makeRequest(request, next, key);
            }

            return of(
              new HttpResponse({
                ...cacheResponse,
                headers: new HttpHeaders(cacheResponse.headers)
              })
            );
          })
        );
    }

    return this.makeRequest(request, next, key);
  }

  generateKey(request: HttpRequest<unknown>) {
    return request.urlWithParams;
  }

  makeRequest(request: HttpRequest<unknown>, next: HttpHandler, key?: string) {
    const cache = request.context.get(CACHE_CONTEXT);

    return next.handle(request).pipe(
      tap((response) => {
        if (cache.set && response instanceof HttpResponse && response.ok) {
          if (!key) {
            key = this.generateKey(request);
          }

          const options: CacheSetOptions = {};
          if (cache.expireIn) {
            options.duration = cache.expireIn;
          }

          if (Array.isArray(cache.tags)) {
            options.tags = cache.tags;
          }

          // Switch to promise, to initiate the observable (better than an empty subscribe)
          void this.cacheService.set(key, response, options).toPromise();
        }
      })
    );
  }
}
