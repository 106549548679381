import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { publishReplay, refCount } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { environment } from '../environments/environment';
import { AuthService } from './common/auth/services/auth.service';

interface ReprintArguments {
  barcode: string;
  printer: number;
  store: string;
}

interface MoveBackToReadyArguments {
  barcode: string;
  store: string;
}

@Injectable()
export class BatchesService implements OnDestroy {
  store = 'lange-beauty.myshopify.com';
  status = 'READY_TO_PRINT';
  username: string;
  private subs = new SubSink();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.subs.sink = this.authService.onSignIn().subscribe((user) => {
      this.username = user.name;
    });
    this.subs.sink = this.authService.onSignOut().subscribe(() => {
      this.username = null;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  speedeemanifest() {
    return this.httpClient
      .get(
        `${environment.wmsService}generate-speedee-manifest?user=${this.username}`
      )
      .pipe(publishReplay(1), refCount());
  }

  lsomanifest() {
    return this.httpClient
      .get(
        `${environment.wmsService}generate-lso-manifest?user=${this.username}`
      )
      .pipe(publishReplay(1), refCount());
  }

  reprintBatchSlip(batchId: string, printer: string | number) {
    return this.httpClient
      .get(
        `${environment.ordersService}${this.store}/reprint-batch-slip/${batchId}/printer/${printer}`
      )
      .pipe(publishReplay(1), refCount());
  }

  reprintOrderByTracking(tracking: string, printer: any, type: string) {
    const reprintUrl = `${environment.ordersService}order-or-tracking/reprint/`;
    return this.httpClient
      .get(
        `${reprintUrl}${tracking}`,
        { params: { printer, user: this.username, type, store: this.store } }
      )
      .pipe(publishReplay(1), refCount());
  }

  fulfill(batchId: string) {
    return this.httpClient
      .get<void>(`${environment.ordersService}fulfill-batch/${batchId}`)
      .pipe(publishReplay(1), refCount());
  }

  reprint({ store, barcode, printer }: ReprintArguments) {
    return this.httpClient
      .get(`${environment.batchPrintingService}${store}/reprint/${barcode}/${printer}`)
      .pipe(publishReplay(1), refCount());
  }

  moveBackToReady({ store, barcode }: MoveBackToReadyArguments) {
    return this.httpClient
      .get(`${environment.batchPrintingService}${store}/move-back-to-ready/${barcode}`)
      .pipe(publishReplay(1), refCount());
  }

  getUsers() {
    return this.httpClient
      .get(`${environment.iamService}users`)
      .pipe(publishReplay(1), refCount());
  }

  getResources() {
    return this.httpClient
      .get(`${environment.iamService}resources`)
      .pipe(publishReplay(1), refCount());
  }

  updateUser(user: Record<string, any>) {
    const { userName, allowedFns, headerMenu, sidebarMenu } = user;
    return this.httpClient
      .post(`${environment.iamService}users/${userName}`, {
        allowedFns,
        headerMenu,
        sidebarMenu
      })
      .pipe(publishReplay(1), refCount());
  }

  deleteUser(userName: string) {
    return this.httpClient
      .get(`${environment.iamService}users/delete/${userName}`)
      .pipe(publishReplay(1), refCount());
  }

  getAllUpsells() {
    return this.httpClient
      .get(`${environment.productRecommendationsService}`)
      .pipe(publishReplay(1), refCount());
  }

  updateUpsellProduct(product: Record<string, any>) {
    const {
      store = 'lange-beauty.myshopify.com',
      sku,
      upsellCollection = '',
      upsellProp = 'upsell',
      upsellRule = ''
    } = product;

    return this.httpClient
      .post(
        `${environment.productRecommendationsService}/${store}/update-product`,
        { upsellCollection, upsellProp, upsellRule, sku }
      )
      .pipe(publishReplay(1), refCount());
  }

  updateUpsellCollection(collection: Record<string, any>) {
    const {
      store = 'lange-beauty.myshopify.com',
      id = '',
      skus = []
    } = collection;

    return this.httpClient
      .post(`${environment.productRecommendationsService}/${store}/update-collection`, { id, skus })
      .pipe(publishReplay(1), refCount());
  }

  provisionDB() {
    return this.httpClient
      .get(`${environment.provisioningManagerService}scale/prod-excelsior-orders`)
      .pipe(publishReplay(1), refCount());
  }
}
