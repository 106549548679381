<div class="container">
  <div class="search-wrapper">
    <mat-form-field>
      <input [(ngModel)]="filterQuery" placeholder="Search..." matInput />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <ng-container
    *ngFor="
      let featureLinksByCategory of featureLinks
        | includeFavorites
        | async
        | navigationFilter: filterQuery
        | groupBy: 'category'
    "
  >
    <mat-expansion-panel
      [expanded]="featureLinksByCategory.category === 'favorite' || filterQuery.length>0"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="category-title">
            {{ featureLinksByCategory.category }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list dense>
        <a
          *ngFor="let featureLink of featureLinksByCategory.groupedItems"
          [routerLink]="'/' + featureLink.path"
          routerLinkActive="active"
          (click)="navigated.emit(true)"
        >
          <mat-list-item>
            <mat-icon matListIcon>{{ featureLink.icon }}</mat-icon>
            <span matLine>
              {{ featureLink.label }}
            </span>
          </mat-list-item>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
  </ng-container>
</div>
