<div class="login_wrapper">
  <div class="login_elements">
    <div class="login_logo">Excelsior!</div>
    <button
      mat-flat-button
      color="default"
      class="login_button"
      (click)="login()"
      [disabled]="preloadSet.has('googleInit')"
      type="submit"
    >
      <preload [value]="preloadSet.has('login') || preloadSet.has('googleInit')"> Login </preload>
    </button>
    <div class="error-message">{{ message }}</div>
  </div>
</div>
