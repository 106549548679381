import { DateTime, Duration, ToRelativeUnit } from 'luxon';

export function isDate(value: unknown): value is Date {
  return (
    value instanceof Date ||
    (typeof value === 'object' &&
      Object.prototype.toString.call(value) === '[object Date]')
  );
}

export function parseDate(value: Date | string) {
  if (typeof value === 'string') {
    if (value === 'now') {
      return new Date();
    }

    const result = DateTime.fromISO(value);
    return result.isValid ? result.toJSDate() : null;
  }

  if (isDate(value)) {
    return value;
  }

  return null;
}

export function absoluteDuration(duration: Duration) {
  if (duration.toMillis() < 0) {
    return duration.negate().normalize();
  }

  return duration;
}

export interface FormatDurationOptions{
  includePrefix?: boolean;
  includeSeconds?: boolean;
}
export const defaultFormatOptions: FormatDurationOptions = { includeSeconds : false,
  includePrefix: false };

const DURATION_UNITS: ToRelativeUnit[] = [
  'years',
  'months',
  'weeks',
  'days',
  'hours',
  'minutes',
  'seconds'
];
export function findLargestUnit(duration: Duration, threshold = 0.8) {
  for (const unit of DURATION_UNITS) {
    if (duration.as(unit) > threshold) {
      return unit;
    }
  }

  return 'seconds';
}

export function durationToString(duration: Duration, unit: ToRelativeUnit | 'auto' = 'auto') {
  const absDuration = absoluteDuration(duration).shiftTo(...DURATION_UNITS);
  if (!absDuration.isValid) {
    return '';
  }

  if (unit !== 'auto') {
    return relativeTimeFormat.format(Math.round(absDuration.as(unit)), unit).slice(3);
  }

  const largestUnit = findLargestUnit(absDuration, 0.8);

  return DURATION_UNITS.slice(
    DURATION_UNITS.findIndex((unit) => unit === largestUnit)
  )
    .map((unit)=> {
      const value = Math.round(absDuration.get(unit));
      return value ? relativeTimeFormat.format(
        Math.round(absDuration.get(unit)), unit
      ).slice(3) : '';
    })
    .filter(unitPartial => unitPartial)
    .join(' ');
}

const relativeTimeFormat = new Intl.RelativeTimeFormat();

export function formatDuration(duration: Duration,
  options?: FormatDurationOptions) {
  if (!duration.isValid) {
    return '';
  }

  const mergedOptions = { ...defaultFormatOptions, ...options };
  const absDuration = absoluteDuration(duration);
  const minutes = absDuration.as('minutes');
  if (!mergedOptions.includeSeconds && minutes <= 1) {
    return relativeTimeFormat.format(1, 'minutes').slice(3);
  }

  const largestUnit = findLargestUnit(absDuration);

  if (!mergedOptions.includePrefix) {
    return relativeTimeFormat.format(Math.round(absDuration.as(largestUnit)), largestUnit).slice(3);
  }

  return relativeTimeFormat.format(Math.round(duration.as(largestUnit)), largestUnit).slice(0);
}

export function compareAsc(
  dirtyDateLeft: Date,
  dirtyDateRight: Date
): number {
  if (!dirtyDateLeft || !dirtyDateRight) {
    return NaN;
  }

  const dateLeft = isDate(dirtyDateLeft) ? dirtyDateLeft : new Date(NaN);
  const dateRight = isDate(dirtyDateRight) ? dirtyDateRight : new Date(NaN);
  const diff = dateLeft.getTime() - dateRight.getTime();

  if (diff < 0) {
    return -1;
  } else if (diff > 0) {
    return 1;
    // Return 0 if diff is 0; return NaN if diff is NaN
  } else {
    return diff;
  }
}

/**
 * Locks given date to a specific timezone - treat given date as it was selected in said timezone
 */
export function fixedTimezone(date: Date, timezone: string) {
  const result = DateTime.fromJSDate(date)
    .setZone(timezone, { keepLocalTime: true });
  return result.toJSDate();
}

export function getStartOfTheDay(date: Date, timezone = 'system') {
  return DateTime.fromJSDate(fixedTimezone(date, timezone))
    .setZone(timezone).startOf('day').toJSDate();
}

export function getEndOfTheDay(date: Date, timezone = 'system') {
  return DateTime.fromJSDate(fixedTimezone(date, timezone))
    .setZone(timezone).endOf('day').toJSDate();
}

export function timeToObject(time: string) {
  if (!time) {
    return null;
  }

  const split = time.split(':');
  if (split.length !== 2) {
    return null;
  }

  const hour = Number(split[0]);
  const minute = Number(split[1]);

  if (isNaN(hour) || isNaN(minute)) {
    return null;
  }

  return { hour, minute };
}

export function timeToDate(time: string, date?: Date) {
  const timeObject = timeToObject(time);
  if (!timeObject) {
    throw new Error('Invalid startsAt time in a slot');
  }

  const baseDate = date || new Date();
  return DateTime.fromJSDate(baseDate).set(timeObject).toJSDate();
}
