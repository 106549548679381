/* eslint-disable max-len */

import { endpoints } from './endpoints';

export const environment = {
  production: true,
  googleOAuthClientId:
    '853394955634-n51ddf7fun9rufqjlelbvmqc4hibbspt.apps.googleusercontent.com',
  ...endpoints
};
