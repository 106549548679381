import { Pipe, PipeTransform } from '@angular/core';
import { FeatureLink } from '@common/navigation/feature-link';
import { NavigationService } from '@common/navigation/navigation.service';

@Pipe({
  name: 'navigationFilter'
})
export class NavigationFilterPipe implements PipeTransform {
  constructor(private navigationService: NavigationService) {
  }

  transform(navigationLinks: FeatureLink[], query: string) {
    return this.navigationService.filterLinks(query, navigationLinks);
  }
}
