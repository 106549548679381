<div class="preload-spinner-wrapper" *ngIf="loading">
  <mat-spinner diameter="30"></mat-spinner>
</div>

<div
  class="preload-content-wrapper"
  [ngStyle]="{ 'min-height': minHeight ? minHeight : 'initial' }"
  [ngClass]="{ loading: loading }"
>
  <ng-content> </ng-content>
</div>
