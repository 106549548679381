import { Injectable } from '@angular/core';

export type AppWindow = Window &
  typeof globalThis & {
  onGapiLoad: ()=> void;
};

@Injectable()
export class WindowService {
  constructor() {}

  getNativeWindow() {
    return window as AppWindow;
  }
}
