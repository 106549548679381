import { CdkTableModule } from '@angular/cdk/table';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import {
  MatButtonToggleModule,
  MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS
} from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatLineModule,
  MatNativeDateModule,
  MatOptionModule,
  MatRippleModule
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettingsModule } from '@app/common/settings/settings.module';
import { AuthTokenInterceptor } from '@common/auth/interceptors/auth-token.interceptor';
import { AuthService } from '@common/auth/services/auth.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { polyfillIntl } from '../polyfills';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { BatchesService } from './batches.service';
import { AuthModule } from './common/auth/auth.module';
import { CacheModule } from './common/cache/cache.module';
import { ListToolbarModule } from './common/components/list-toolbar/list-toolbar.module';
import { PreloadModule } from './common/interface/preload/preload.module';
import { RoasClassPipeModule } from './common/pipes/roas-class/roas-class-pipe.module';
import { VALIDATION_MESSAGES } from './common/pipes/validation-error/validation-error.token';
import { ShellModule } from './common/shell/shell.module';
import { ProductUpsellDialogComponent } from './dialog-components/product-upsell-dialog.component';
import { StopOrderDialogComponent } from './dialog-components/stop-order-dialog.component';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { UserModule } from './features/user/user.module';
import { LoaderService } from './loader.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WindowService } from './window.service';

export function initializeApp(authService: AuthService) {
  return () => {
    const initUser = authService.initUserFromStorage().catch(error => {
      console.error(error);
      throw error;
    });
    return Promise.all([initUser, polyfillIntl('en')]);
  };
}

export const LOCKED_TIMEZONE = new InjectionToken<string>('locked-timezone');

@NgModule({
  declarations: [
    AppComponent,
    StopOrderDialogComponent,
    ProductUpsellDialogComponent,
    PageNotFoundComponent
  ],
  entryComponents: [
    StopOrderDialogComponent,
    ProductUpsellDialogComponent
  ],
  imports: [
    AppRoutingModule,
    AuthModule,
    // TODO: remove UserModel when user-management-dashboard is moved to a module
    UserModule,
    DashboardModule,
    PreloadModule,
    ListToolbarModule,
    ShellModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    CdkTableModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatRippleModule,
    MatIconModule,
    MatSidenavModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatSortModule,
    MatChipsModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatListModule,
    MatLineModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatGridListModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatOptionModule,
    MatSliderModule,
    MatStepperModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatSlideToggleModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    SettingsModule,
    RoasClassPipeModule,
    CacheModule
  ],
  providers: [
    BatchesService,
    LoaderService,
    WindowService,
    DecimalPipe,
    CurrencyPipe,
    {
      provide: LOCKED_TIMEZONE,
      useValue: 'America/New_York'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService],
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        maxWidth: '96%'
      }
    },
    {
      provide: VALIDATION_MESSAGES,
      useValue: {
        required: 'This field is required',
        rangeValidator: 'This range is invalid',
        arrayDistinctValidatorChild: 'This field has to be unique'
      },
      multi: true
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'legacy'
      }
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 4000 } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}
