import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@common/cache/services/cache.service';
import { CACHE_MODELS_KEY } from '@common/common.constants';
import { Query } from '@common/http/query';
import { toSnakecase } from '@common/utils/rest.utils';
import { environment } from '@environments/environment';
import { UserResources } from '@features/user-management/models/user-resources.model';
import { UpdateMenuDto } from '@features/user/dto/update-menu.dto';
import { UpdateUserDto } from '@features/user/dto/update-user.dto';
import { AddUserDto } from '@features/user/models/add-user.dto';
import { map, mapTo, pluck, tap } from 'rxjs/operators';
import { UpdateUsersFunctionsDto } from '../dto/update-users-functions.dto';
import { UserAllQueryDto } from '../dto/user-all-query.dto';
import { USER_CACHE, User } from '../models/user.model';

export const DEFAULT_USER_ICON =
  'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png';
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private readonly http: HttpClient, private cacheService: CacheService) {}

  all(query?: Query<UserAllQueryDto>) {
    return this.http.get<User[]>(
      `${environment.iamService}users`,
      { params: query?.toPlain(), context:query?.context }
    );
  }

  updateUserMenus(updateMenuDto: UpdateMenuDto) {
    return this.http.patch<void>(`${environment.iamService}user/menu`, {
      favorites: updateMenuDto.favorites
    });
  }

  updateUser(updateUserDTO: UpdateUserDto) {
    return this.http.post(`${environment.iamService}users/${updateUserDTO.userName}`, {
      allowedFns: updateUserDTO.allowedFns,
      headerMenu: updateUserDTO.headerMenu,
      sidebarMenu: updateUserDTO.sidebarMenu
    });
  }

  updateUsersFunctions(updateUsersFunctionsDto: UpdateUsersFunctionsDto) {
    return this.http.patch(`${environment.iamService}users-functions`, {
      users: updateUsersFunctionsDto.users
    });
  }

  public store(addUserData: AddUserDto) {
    return this.http
      .post<void>(
        `${environment.iamService}users`,
        { data:toSnakecase(addUserData) }
      )
      .pipe(
        tap(() => {
          this.cacheService.forget({ tags:[CACHE_MODELS_KEY, USER_CACHE] }).subscribe();
        }),
        mapTo(true)
      );
  }

  getUser(username: string) {
    return this.http
      .get<{ data: User; }>(`${environment.iamService}user/${username}`)
      .pipe(pluck('data'), map(user => {
        if (!user.allowedFns) {
          user.allowedFns = [];
        }

        if (!user.headerMenu) {
          user.headerMenu = [];
        }

        if (!user.sidebarMenu) {
          user.sidebarMenu = [];
        }

        return user;
      }));
  }

  getResources() {
    return this.http.get<UserResources>(`${environment.iamService}resources`);
  }

  deleteUser(userName: string) {
    return this.http.delete(`${environment.iamService}users/${userName}`).pipe(mapTo(true));
  }
}
