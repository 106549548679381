import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { HeaderService } from '@app/common/header/header.service';
import { MenuItem } from '@app/features/user/models/user.model';
import { AuthUser } from '@common/auth/models/auth-user.model';
import { AuthService } from '@common/auth/services/auth.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnDestroy {
  @ViewChild('sidenav')
  sidenav: MatSidenav;

  private subs = new SubSink();

  sidebarMenu: MenuItem[] = [];

  constructor(public headerService: HeaderService, private authService: AuthService) {
    this.subs.sink = this.authService.getUser().subscribe((user: AuthUser) => {
      this.sidebarMenu = user.sidebarMenu;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  closeDrawer() {
    // TODO: properly handle error, e.g. add ".catch(console.warn);"
    void this.sidenav.close();
  }
}
