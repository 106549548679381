import { NgZone } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';

/**
 * Custom OperatorFunction that makes sure that all lifecycle hooks of an Observable
 * are running in the NgZone.
 *
 * @todo this is not the proper way to type the output of this function
 */
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return source => new Observable(observer => {
    const onNext = (value: T) => zone.run(() => observer.next(value));
    const onError = (e: any) => zone.run(() => observer.error(e));
    const onComplete = () => zone.run(() => observer.complete());
    return source.subscribe(onNext, onError, onComplete);
  });
}
