import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PreloadComponent } from './preload.component';

@NgModule({
  declarations: [PreloadComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [PreloadComponent]
})
export class PreloadModule {}
