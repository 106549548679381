import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '@common/navigation/navigation.service';
import { FeatureLink } from '@common/navigation/feature-link';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  featureLinks: FeatureLink[];
  subs = new SubSink();
  favorites$ = this.navigationService.getFavorites();
  filterQuery = '';

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    this.featureLinks = this.navigationService.getFeatureLinks();
  }

  isIconInFavorite(featureLink: FeatureLink) {
    return this.navigationService.getFavoritesSnapshot().includes(featureLink.path);
  }

  resolveFavorite(featureLink: FeatureLink, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if(this.isIconInFavorite(featureLink)) {
      this.subs.sink = this.navigationService.removeFavorite(featureLink.path).subscribe();
    } else{
      this.subs.sink = this.navigationService.addFavorite(featureLink.path).subscribe();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
