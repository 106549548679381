import { Pipe, PipeTransform } from '@angular/core';
import { FeatureLink } from '@common/navigation/feature-link';
import {
  FAVORITE_CATEGORY_NAME,
  NavigationService
} from '@common/navigation/navigation.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'includeFavorites'
})
export class IncludeFavoritesPipe implements PipeTransform {
  constructor(private navigationService: NavigationService) {}

  transform(navigationLinks: FeatureLink[]) {
    return this.navigationService.getFavoriteFeatureLinks().pipe(
      map((favorites) => [
        ...favorites.map((favorite) => ({ ...favorite, category: FAVORITE_CATEGORY_NAME })),
        ...navigationLinks
      ])
    );
  }
}
