import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@app/common/auth/services/auth.service';
import { MenuItem } from '@app/features/user/models/user.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  //TODO: unused property, check if it can be removed
  menus$: Observable<MenuItem[]> = this.authService.getUser().pipe(
    filter((user) => !!user),
    map((user): MenuItem[] =>
      [...user.headerMenu, ...user.sidebarMenu].map((menuItem) => {
        if (menuItem.resourceName === 'box-packing') {
          return { ...menuItem, resourceName: 'boxes/orders' };
        }

        return menuItem;
      }))
  );

  constructor(private authService: AuthService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle('Home');
  }
}
