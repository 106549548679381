import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot, CanActivate,
  Router, RouterStateSnapshot
} from '@angular/router';
import { stripQuery } from '@app/common/http/utils/http.utils';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  public redirectUrl = '';
  public redirectQuery: Record<string, any> = {};
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isUserLoggedIn()) {
      const { url } = stripQuery(state.url);
      this.redirectUrl = url;
      this.redirectQuery = route.queryParams;
      this.router.navigate(['/login']).catch((error)=>{
        console.warn(error);
        this.snackBar.open('Could not navigate to route \'/login\'');
      });
      return false;
    }

    if (
      typeof route.data !== 'undefined' &&
      Array.isArray(route.data.allPermissions)
    ) {
      const allPermissions = route.data.allPermissions;

      const result = this.authService.hasAccess(allPermissions);

      if (result === false) {
        this.snackBar.open(`You do not have permissions to access this module (${state.url})`);
        this.router.navigate(['/']).catch(error=>{
          console.warn(error);
          this.snackBar.open('Could not navigate to route \'/\'');
        });

        return false;
      }
    }

    return true;
  }
}
