import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthGuardService } from '@common/auth/services/auth-guard.service';
import { AuthService } from '@common/auth/services/auth.service';
import { BroadcastChannelService, LOGIN_MESSAGE } from '@common/services/broadcast-channel.service';
import { SubSink } from 'subsink';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  public message: string;
  preloadSet = new Set<string>();
  subs = new SubSink();

  constructor(
    private authService: AuthService,
    private authGuard: AuthGuardService,
    private changeDetector: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private router: Router,
    private broadcastChannelService: BroadcastChannelService,
    private titleService: Title
  ) {
    this.setMessage();
  }

  ngOnInit() {
    this.titleService.setTitle('Login');
    this.preloadSet.add('googleInit');
    this.subs.sink = this.broadcastChannelService.message$.subscribe(value => {
      if (value === LOGIN_MESSAGE) {
        // TODO: do not use the second parameter to .then()

        void this.authService.initUserFromStorage().then(() => {
          this.redirect();
        }, () => {
          this.snackBar.open('Could not initiate from storage');
        });
      }
    });

    this.subs.sink = this.authService.onSignIn().subscribe(() => {
      this.redirect();
    });
  }

  async ngAfterViewInit() {
    this.authService.googleInit().subscribe(
      () => {
        this.preloadSet.delete('googleInit');
        this.changeDetector.detectChanges();
      },
      (error) => {
        this.snackBar.open('Could not initiate google api');
        console.warn(error);
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setMessage(message = '') {
    this.message = message;
  }

  async login() {
    this.preloadSet.add('login');
    try {
      const success = await this.authService.googleSignIn();
      this.preloadSet.delete('login');
      if (!success) {
        this.setMessage('There was a problem authenticating your account.');
      }

      this.redirect();
    } catch (error) {
      this.preloadSet.delete('login');
      if (!error || typeof error.error !== 'string' || error.error !== 'popup_closed_by_user') {
        this.setMessage('There was a problem authenticating your account.');
      }
    }
  }

  logout() {
    void this.authService.signOut();
  }

  redirect() {
    if (this.authService.isUserLoggedIn()) {
      const redirectUrl = this.authGuard.redirectUrl || '/';

      this.router.navigate([redirectUrl], {
        queryParams: this.authGuard.redirectQuery
      }).catch(error => {
        console.warn(error);
        this.snackBar.open(`Could not navigate to '${redirectUrl}'`);
      });
    }
  }
}
