<ng-container *ngIf="user">
  <button mat-button [matMenuTriggerFor]="userMenu" class="button">
    <span class="profile-picture"><img src="{{ user.picture }}" /></span>
    <div class="username-wrapper">
      <div class="username">
        <div>{{ user.name }}</div>
        <div class="brand">
          <ng-container *ngIf="currentBrand; else noBrand">
            <span
              class="brand-color"
              [ngStyle]="{ 'background-color': currentBrand.color }"
            ></span
            >{{ currentBrand.label }}
          </ng-container>
          <ng-template #noBrand> no brand selected </ng-template>
        </div>
      </div>

      <mat-icon class="username-arrow">keyboard_arrow_down</mat-icon>
    </div>
  </button>
  <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
    <button mat-menu-item [matMenuTriggerFor]="brandsMenu">
      <mat-icon>business</mat-icon>
      <span>Brands</span>
    </button>
    <button mat-menu-item class="" routerLink="/logout">
      <mat-icon>exit_to_app</mat-icon>
      <span>Log out</span>
    </button>
  </mat-menu>
  <mat-menu #brandsMenu="matMenu" [overlapTrigger]="false">
    <button
      mat-menu-item
      *ngFor="let brand of brands$ | async"
      (click)="switchBrand(brand)"
      class="brand"
    >
      <div class="r :a-ce">
        <div><span class="brand-color" [ngStyle]="{ 'background-color': brand.color }"></span>{{ brand.label }}</div>
        <mat-icon *ngIf="brand.key === currentBrand.key">done</mat-icon>
      </div>
    </button>
  </mat-menu>
</ng-container>
