<mat-toolbar color="primary">
  <button (click)="navOpen()" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <ng-container *ngIf="user$ | async as user">
    <app-user-profile-menu [user]="user"></app-user-profile-menu>

    <span class="spacer"></span>
    <a routerLink="/" mat-icon-button>
      <mat-icon matTooltip="Home" matTooltipPosition="below">home</mat-icon>
    </a>
    <ng-container *ngIf="favorites">
      <div
        cdkDropList
        (cdkDropListDropped)="dropLink($event)"
        cdkDropListOrientation="horizontal"
      >
        <a
          *ngFor="let favorite of favorites"
          [routerLink]="'/' + favorite.path"
          cdkDrag
          mat-icon-button
          (dragstart)="$event.preventDefault()"
        >
          <mat-icon [matTooltip]="favorite.label" matTooltipPosition="below">{{
            favorite.icon
          }}</mat-icon>
        </a>
      </div>
    </ng-container>
  </ng-container>

  <button
    class="excelsior-spinner"
    [disabled]="!headerService.hasRefreshCallback()"
    (click)="headerService.refresh()"
    mat-icon-button
  >
    <mat-icon matTooltip="Refresh Data" matTooltipPosition="below"
      >autorenew</mat-icon
    >
  </button>
</mat-toolbar>
