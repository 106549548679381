import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@common/header/header.module';
import { ShellComponent } from './shell.component';
import { MenuModule } from '@common/menu/menu.module';

@NgModule({
  declarations: [ShellComponent],
  imports: [
    CommonModule,
    HeaderModule,
    MatProgressBarModule,
    MatListModule,
    MatSidenavModule,
    RouterModule,
    MatIconModule,
    MenuModule
  ],
  exports: [ShellComponent]
})
export class ShellModule {}
