import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@common/auth/services/auth.service';
import { NavigationService } from '@common/navigation/navigation.service';
import { SubSink } from 'subsink';
import { FeatureLink } from '../navigation/feature-link';
import { moveItemInArray } from '../utils/drag.utils';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  headerMenu: any = [];
  user$ = this.authService.getUser();
  favorites: FeatureLink[] ;
  isRefreshInProgress = false;
  private subs = new SubSink();

  @Output()
  navToggle = new EventEmitter<boolean>();

  @Output()
  spin = new EventEmitter<boolean>();

  @HostBinding('class')
  className = 'app-header';

  constructor(private authService: AuthService, private router: Router,
    public headerService: HeaderService,
    private navigationService: NavigationService) {}

  ngOnInit() {
    this.subs.sink = this.navigationService.getFavoriteFeatureLinks().subscribe(favoriteLinks => {
      this.favorites = favoriteLinks;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  navOpen() {
    this.navToggle.emit(true);
  }

  logOut() {
    void this.router.navigate(['/logout']);
  }

  dropLink(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    const reorderedFavorites = moveItemInArray(this.favorites, event.previousIndex,
      event.currentIndex);
    const newFavorites = reorderedFavorites.map(favoriteLink => favoriteLink.path);
    this.navigationService.updateFavorites(newFavorites).subscribe(() => {});
  }
}
