import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Brand } from '@app/common/brand/models/brand.model';
import { SelectData } from '@app/common/common.types';
import { DistributionCenter } from '@app/common/settings/models/settings.model';
import { Printer } from '@common/settings/models/printer.model';
import { environment } from '@environments/environment';
import { of } from 'rxjs';
import { pluck } from 'rxjs/operators';

export const distributionCenters: DistributionCenter[] = [
  {
    key: 'DC1-US-WEST',
    label: 'DC1-US-WEST - California',
    printer: 70807739,
    timezone: 'America/Los_Angeles',
    userPrefix: 'warehouse1',
    color: 'rgb(0, 255, 220)'
  },
  {
    key: 'DC2-US-EAST',
    label: 'DC2-US-EAST - Ohio',
    printer: 70768987,
    timezone: 'America/New_York',
    userPrefix: 'warehouse2',
    color: 'rgb(190, 34, 255)'
  }
];

export const warehouseLines: SelectData[] = [
  {
    key: '1',
    label: 'Line 1'
  },
  {
    key: '2',
    label: 'Line 2'
  },
  {
    key: '3',
    label: 'Line 3'
  },
  {
    key: '4',
    label: 'Line 4'
  },
  {
    key: 'singles-1',
    label: 'Singles 1'
  },
  {
    key: 'singles-2',
    label: 'Singles 2'
  },
  {
    key: 'grid-packing',
    label: 'Grid packing'
  }
];

export const brands: Brand[] = [
  {
    key: 'lange',
    // cSpell:ignore L\'ange
    label: 'L\'ange',
    color: '#F4C5CE',
    url: 'lange-beauty.myshopify.com'
  },
  {
    key: 'upness',
    label: 'Upness',
    color: '#E2C999',
    url: 'upness-prod.myshopify.com'
  },
  {
    key: 'ofir',
    label: 'Ofir',
    color: '#e2bc99',
    url: 'ofir-beauty.myshopify.com' // Unknown
  }
  // {
  //   key: "test",
  //   label: "Test",
  //   color: "#929292",
  // },
];

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private httpClient: HttpClient) {}

  getSettings() {
    return of({
      distributionCenters,
      brands,
      warehouseLines
    });
  }

  getDistributionCenters() {
    return this.getSettings().pipe(pluck('distributionCenters'));
  }

  getWarehouseLines() {
    return this.getSettings().pipe(pluck('warehouseLines'));
  }

  getBrands() {
    return this.getSettings().pipe(pluck('brands'));
  }

  getPrinters() {
    return this.httpClient.get<Printer[]>(`${environment.ordersService}printers`);
  }
}
