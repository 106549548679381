<mat-sidenav-container class="fill">
  <app-header (navToggle)="sidenav.open()"></app-header>
  <mat-progress-bar
    class="hidden"
    [ngClass]="{ hidden: (headerService.isLoading() | async) === false }"
    mode="indeterminate"
    color="accent"
  >
  </mat-progress-bar>
  <router-outlet class="fill"></router-outlet>
  <mat-sidenav #sidenav>
      <app-sidebar-menu></app-sidebar-menu>
  </mat-sidenav>
</mat-sidenav-container>
