import { Injectable, NgZone } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';
import { fromEvent, Observable } from 'rxjs';
import { runInZone } from '../operators/run-in-zone';

export const LOGIN_MESSAGE = 'LOGIN';
export const LOGOUT_MESSAGE = 'LOGOUT';

@Injectable({
  providedIn: 'root'
})
export class BroadcastChannelService {
  channel: BroadcastChannel;

  message$: Observable<string>;

  constructor(private ngZone: NgZone) {
    this.init();
  }

  init() {
    this.channel = new BroadcastChannel<string>('EXCELSIOR');

    this.message$ = fromEvent(this.channel, 'message').pipe(runInZone(this.ngZone));
  }

  postMessage(message: string) {
    // TODO: implement proper error handling

    void this.channel.postMessage(message);
  }
}
