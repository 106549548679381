import { PreloadModule } from '@common/interface/preload/preload.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { HeaderModule } from '@app/common/header/header.module';
import { LogoutComponent } from './pages/logout/logout.component';
import { LoginComponent } from './pages/login/login.component';
import { IsAuthenticatedDirective } from './directives/is-authenticated/is-authenticated.directive';
import { IsAuthorizedDirective } from './directives/is-authorized/is-authorized.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ReLoginDialogComponent } from './dialogs/re-login/re-login-dialog.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    IsAuthenticatedDirective,
    IsAuthorizedDirective,
    ReLoginDialogComponent
  ],
  imports: [
    HeaderModule,
    MatDialogModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    PreloadModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  exports: [IsAuthenticatedDirective, IsAuthorizedDirective]
})
export class AuthModule {}
