export const USER_CACHE = 'User';

export interface MenuItem {
  label: string;
  'material-icon': string;
  resourceName: string;
}

export interface User {
  allowedFns: string[];
  favorites: string[];
  /**
   * @deprecated
   */
  headerMenu: MenuItem[];
  picture: string;
  /**
   * @deprecated
   */
  sidebarMenu: MenuItem[];
  userName: string;
}
