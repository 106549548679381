import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastChannelService, LOGOUT_MESSAGE } from '@common/services/broadcast-channel.service';
import { SubSink } from 'subsink';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  googleClientId = environment.googleOAuthClientId;
  subs = new SubSink();

  constructor(private broadcastChannelService: BroadcastChannelService, private router: Router) {}

  ngOnInit() {
    this.subs.sink = this.broadcastChannelService.message$.subscribe(message => {
      if (message === LOGOUT_MESSAGE) {
        this.router.navigateByUrl('/login').catch(console.warn);
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
