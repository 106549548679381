import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-re-login-dialog',
  templateUrl: './re-login-dialog.component.html'
})
export class ReLoginDialogComponent {
  preloadSet = new Set<string>();
  isError = false;

  constructor(private dialogRef: MatDialogRef<ReLoginDialogComponent>,
    private authService: AuthService, private router: Router) {}

  logout() {
    this.router.navigate(['logout']).catch(console.warn);
    this.dialogRef.close(false);
  }

  refresh() {
    this.isError = false;
    this.preloadSet.add('googleSignIn');

    // TODO: do not use the second parameter to .then(), prefer to use .catch, then eventually,
    // prefer to refactor refresh() as async and use await with try/catch.

    this.authService.googleSignIn().then(
      () => {
        this.preloadSet.delete('googleSignIn');
        this.dialogRef.close(true);
      },
      () => {
        this.preloadSet.delete('googleSignIn');
        this.isError = true;
      }
    );
  }
}
