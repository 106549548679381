import { HttpContextToken } from '@angular/common/http';
import { DurationLike } from 'luxon';

export interface QueryCacheParams {
  expireIn?: DurationLike;
  get?: boolean;
  set?: boolean;
  tags?: string[];
  youngerThan?: DurationLike;
}

export const DEFAULT_CACHE_PARAMS: QueryCacheParams = {
  get: false,
  set: false
};

export const CACHE_CONTEXT = new HttpContextToken<QueryCacheParams>(() => DEFAULT_CACHE_PARAMS);
