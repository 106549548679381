import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

// TODO: address the linting issues and remove the directives

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'preload',
  templateUrl: './preload.component.html',
  styleUrls: ['./preload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreloadComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value')
  loading = false;

  @Input()
  minHeight: string;

  private _inline = false;

  @Input()
  public set inline(value) {
    this._inline = value;
    this.className = this.generateClassName();
  }

  public get inline() {
    return this._inline;
  }

  _color = 'default';
  _fill = false;

  @HostBinding('class')
  className = 'mat-default';

  constructor() {
  }

  @Input()
  set color(value: string) {
    this._color = value;
    this.className = this.generateClassName();
  }

  get color() {
    return this._color;
  }

  @Input()
  set fill(value: boolean) {
    this._fill = value;
    this.className = this.generateClassName();
  }

  get fill() {
    return this._fill;
  }

  generateClassName() {
    let result = 'mat-' + this._color;

    if (this.fill) {
      result += ' fill';
    }

    if (this.inline) {
      result += ' inline';
    }

    return result;
  }
}
