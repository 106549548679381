import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthModule } from '@app/common/auth/auth.module';
import { PreloadModule } from '@app/common/interface/preload/preload.module';
import { MenuEditorComponent } from './components/menu-editor.component';

@NgModule({
  declarations: [MenuEditorComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MatListModule,
    AuthModule,
    DragDropModule,
    MatGridListModule,
    MatIconModule,
    PreloadModule,
    MatButtonModule
  ],
  exports: [MenuEditorComponent]
})
export class UserModule {}
