<h2 mat-dialog-title>
  Session expired
</h2>
<mat-dialog-content>
  <preload [value]="preloadSet.has('googleSignIn')">
    Your session expired, would you like to refresh it, or logout?
  </preload>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    color="primary"
    aria-label="Refresh session"
    (click)="refresh()"
    [disabled]="preloadSet.has('googleSignIn')"
  >
    Refresh
  </button>
  <button
    mat-button
    color="warn"
    aria-label="Log out"
    (click)="logout()"
    [disabled]="preloadSet.has('googleSignIn')"
  >
    Log out
  </button>
</mat-dialog-actions>
