import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {
  transform(value: any[], findItem: any) {
    return value.includes(findItem);
  }
}
