import { DateTime, DurationLike } from 'luxon';
import { asyncScheduler, of, Subject } from 'rxjs';
import { filter, map, observeOn } from 'rxjs/operators';
import { compareAsc } from '../utils/date.utils';
import {
  CacheDriver,
  CacheMatchOptions,
  CacheSetOptions,
  DEFAULT_CACHE_SET_OPTIONS,
  WrappedValue
} from './cache-driver';

interface SerializedValue<T> {
  createdAt: string;
  expiresAt: string | null;
  tags: string[] | null;
  value: T;
}

interface CacheUpdateEvent<T> {
  key: string;
  value: T;
}

export class MemoryDriver implements CacheDriver {
  cache: Map<string, string> = new Map();
  prefix = '';
  observe$ = new Subject<CacheUpdateEvent<unknown>>();

  constructor() {
    this.flush(true);
  }

  get<T>(key: string, youngerThan?: DurationLike) {
    const now = new Date();
    const wrappedValue: WrappedValue<T> = this.deserialize(this.cache.get(this.prefix + key));
    if (!wrappedValue) {
      return of<undefined>(undefined).pipe(observeOn(asyncScheduler));
    }

    if (!this.isYoungEnough(wrappedValue, youngerThan, now)) {
      return of<undefined>(undefined).pipe(observeOn(asyncScheduler));
    }

    return of(this.expireIfNeeded<T>(key, wrappedValue, now)).pipe(observeOn(asyncScheduler));
  }

  private isYoungEnough<T>(wrappedValue: WrappedValue<T>, youngerThan?: DurationLike,
    now?: Date) {
    if (!youngerThan) {
      return true;
    }

    const nowDateTime = now ? DateTime.fromJSDate(now) : DateTime.now();
    const checkDate = nowDateTime.minus(youngerThan).toJSDate();

    const compareResult = compareAsc(wrappedValue.createdAt, checkDate);
    if (compareResult === -1) {
      return false;
    }

    return true;
  }

  private expireIfNeeded<T>(key: string, wrappedValue: WrappedValue<T>, now?: Date) {
    const compareResult = compareAsc(wrappedValue.expiresAt, now ? now : new Date());
    if (compareResult < 1) {
      this.observe$.next({ key, value: undefined });
      this.cache.delete(this.prefix + key);
    }

    if (compareResult === -1) {
      return undefined;
    }

    return wrappedValue.value;
  }

  set<T>(key: string, value: T, options: CacheSetOptions = {}) {
    const mergedOptions = { ...DEFAULT_CACHE_SET_OPTIONS, ...options };

    const nowDateTime = DateTime.now();

    const wrappedValue: WrappedValue<T> = {
      value,
      tags: mergedOptions.tags ? mergedOptions.tags : null,
      createdAt: nowDateTime.toJSDate(),
      expiresAt: mergedOptions.duration ?
        nowDateTime.plus(mergedOptions.duration).toJSDate() :
        null
    };

    this.cache.set(this.prefix + key, this.serialize(wrappedValue));

    if (mergedOptions.emitEvent) {
      this.observe$.next({ key, value });
    }

    return of(true).pipe(observeOn(asyncScheduler));
  }

  observe<T>(key: string) {
    return this.observe$.pipe(filter(event => event.key === key), map(event => <T>event.value));
  }

  flush(expiredOnly = false) {
    if (expiredOnly) {
      const now = new Date();
      for (const [cachedKey, cachedValue] of this.cache.entries()) {
        const wrappedValue: WrappedValue<unknown> = this.deserialize(cachedValue);
        this.expireIfNeeded(cachedKey, wrappedValue, now);
      }
    } else {
      this.cache.clear();
    }

    return of(true).pipe(observeOn(asyncScheduler));
  }

  private findItemKeys(match: CacheMatchOptions) {
    if (match.key) {
      return [match.key];
    } else if(match.tags) {
      const matchedKeys: string[] = [];
      for (const [key, value] of this.cache.entries()) {
        const deserialized = this.deserialize(value);
        if (deserialized.tags && match.tags.every(tag=> deserialized.tags.includes(tag))) {
          matchedKeys.push(key);
        }
      }

      return matchedKeys;
    }
  }

  forget(match: CacheMatchOptions) {
    const foundItemKeys = this.findItemKeys(match);
    if (foundItemKeys.length === 0) {
      return of(false).pipe(observeOn(asyncScheduler));
    }

    let result = true;
    for (const key of foundItemKeys) {
      const singleResult = this.cache.delete(this.prefix + key);
      if (singleResult) {
        this.observe$.next({ key, value: undefined });
      }

      result = result && singleResult;
    }

    return of(result).pipe(observeOn(asyncScheduler));
  }

  private deserialize<T>(value?: string) {
    if (!value) {
      return;
    }

    // TODO: casting a parsed value to type SerializedValue is strange, serialization generally
    // refers to the string form of the value.

    const parsed: SerializedValue<T> = JSON.parse(value);
    return <WrappedValue<T>>{
      ...parsed,
      createdAt: DateTime.fromISO(parsed.createdAt).toJSDate(),
      expiresAt: parsed.expiresAt ? DateTime.fromISO(parsed.expiresAt).toJSDate() : null
    };
  }

  private serialize(value: unknown): string {
    return JSON.stringify(value);
  }

  destroy() {
    this.observe$.complete();
  }
}
