import { DurationLike } from 'luxon';
import { Observable } from 'rxjs';

export interface WrappedValue<T> {
  createdAt: Date;
  expiresAt: Date | null;
  tags: string[] | null;
  value: T;
}
export interface CacheSetOptions {
  duration?: DurationLike;
  emitEvent?: boolean;
  get?: boolean;
  set?: boolean;
  tags?: string[];
}

export interface CacheMatchOptions {
  key?: string;
  tags?: string[];
}

export const DEFAULT_CACHE_SET_OPTIONS: CacheSetOptions = {
  get:true,
  set:true,
  duration: null,
  emitEvent: true
};
export interface CacheDriver {
  destroy(): void;
  flush(expiredOnly?: boolean): Observable<boolean>;
  forget(options: CacheMatchOptions): Observable<boolean>;
  get<T>(key: string, youngerThan?: DurationLike): Observable<T | undefined>;
  observe<T>(key: string): Observable<T | undefined>;
  set<T>(key: string, value: T, options?: CacheSetOptions): Observable<boolean>;
}
