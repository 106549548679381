import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { WindowService } from '@app/window.service';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { MemoryDriver } from './memory-driver';
import { CacheService, CACHE_DRIVERS } from './services/cache.service';
import { WebStorageDriver } from './web-storage-driver';

@NgModule({
  providers: [
    CacheService,
    {
      provide: CACHE_DRIVERS,
      useFactory: (windowService: WindowService) => [
        {
          name: 'memory',
          driver: new MemoryDriver()
        },
        {
          name: 'storage',
          driver: new WebStorageDriver(
            windowService.getNativeWindow().window,
            'local'
          )
        }
      ],
      deps: [WindowService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    }
  ]
})
export class CacheModule {}
