import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthUser } from '@app/common/auth/models/auth-user.model';
import { Brand } from '@app/common/brand/models/brand.model';
import { BrandService } from '@app/common/brand/services/brand.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent implements OnInit, OnDestroy {
  @Input()
  user: AuthUser;

  private subs = new SubSink();

  brands$ = this.brandService.getAll();
  currentBrand: Brand = null;

  constructor(private brandService: BrandService) {}

  ngOnInit() {
    this.subs.sink = this.brandService.get().subscribe(brand => this.currentBrand = brand);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  switchBrand(brand: Brand) {
    this.brandService.set(brand);
  }
}
